export const spineFile = [
  'ambient',
  'reelframe',
  'spider',
  'stagechange',
  'symbol_all',
  'symbol_multiplier',
  'symbol_scatter',
  'win',
] as const;

export type SpineFile = (typeof spineFile)[number];

export interface SpineInterface {
  ambient: { skins: 'default'; animations: 'basegame' | 'featuregame' };
  reelframe: {
    skins: 'default';
    animations: 'bg_idle' | 'bg_spinend' | 'bg_win' | 'fg_idle' | 'fg_spinend' | 'fg_win';
  };
  spider: {
    skins: 'default' | 'base_core' | 'base_po' | 'feauture_core' | 'feauture_po';
    animations:
      | 'bg_appeal'
      | 'bg_attack'
      | 'bg_idle'
      | 'bg_move_l'
      | 'bg_move_r'
      | 'bg_win'
      | 'fg_appeal'
      | 'fg_attack'
      | 'fg_idle'
      | 'fg_move_l'
      | 'fg_move_r'
      | 'fg_win';
  };
  stagechange: {
    skins: 'default' | 'base_core' | 'base_po' | 'feauture_core' | 'feauture_po';
    animations: 'bg_stagechange' | 'fg_stagechange';
  };
  symbol_all: {
    skins: 'default' | 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i' | 'j';
    animations:
      | 'step1_in'
      | 'step1_lost'
      | 'step1_stop'
      | 'step1_win'
      | 'step2_in'
      | 'step2_lost'
      | 'step2_stop'
      | 'step2_win'
      | 'step3_in'
      | 'step3_lost'
      | 'step3_stop'
      | 'step3_win'
      | 'step4_in'
      | 'step4_lost'
      | 'step4_stop'
      | 'step4_win'
      | 'step5_in'
      | 'step5_lost'
      | 'step5_stop'
      | 'step5_win';
  };
  symbol_multiplier: {
    skins: 'default';
    animations:
      | 'step1_in'
      | 'step1_lost'
      | 'step1_stop'
      | 'step1_win'
      | 'step2_in'
      | 'step2_lost'
      | 'step2_stop'
      | 'step2_win'
      | 'step3_in'
      | 'step3_lost'
      | 'step3_stop'
      | 'step3_win'
      | 'step4_in'
      | 'step4_lost'
      | 'step4_stop'
      | 'step4_win'
      | 'step5_in'
      | 'step5_lost'
      | 'step5_stop'
      | 'step5_win';
  };
  symbol_scatter: {
    skins: 'default';
    animations:
      | 'step1_in'
      | 'step1_lost'
      | 'step1_stop'
      | 'step1_win'
      | 'step2_in'
      | 'step2_lost'
      | 'step2_stop'
      | 'step2_win'
      | 'step3_in'
      | 'step3_lost'
      | 'step3_stop'
      | 'step3_win'
      | 'step4_in'
      | 'step4_lost'
      | 'step4_stop'
      | 'step4_win'
      | 'step5_in'
      | 'step5_lost'
      | 'step5_stop'
      | 'step5_win';
  };
  win: {
    skins: 'default';
    animations:
      | 'bigwin_in'
      | 'bigwin_loop'
      | 'bigwin_out'
      | 'epicwin_in'
      | 'epicwin_loop'
      | 'epicwin_out'
      | 'greatwin_in'
      | 'greatwin_loop'
      | 'greatwin_out'
      | 'megawin_in'
      | 'megawin_loop'
      | 'megawin_out';
  };
}

export const ENABLE_MERGED_ATRAS = false;

export const preloadSpineTextures = [];

export const mobileVersionSpineFile = ['ambient', 'coins', 'reelframe', 'stagechange'];
