import { ResourceTypes } from '../resources.d';

export const introContents = [
  {
    img: ResourceTypes.intro1,
    imgX: -10,
    txtKey: 'introTitle',
  },
  {
    img: ResourceTypes.intro2,
    imgX: 0,
    txtKey: 'introTitle2',
  },
  {
    img: ResourceTypes.intro3,
    imgX: -15,
    txtKey: 'introTitle3',
  },
];
