import { useQuery } from '@apollo/client';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@phoenix7dev/shared-components';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { config } from '../../config';
import { freeRoundBonusId } from '../../global.d';
import { setCurrency, setSlotConfig } from '../../gql/cache';
import { ISlotHistoryData } from '../../gql/d';
import { slotHistoryGql } from '../../gql/query';
import { onClickHistoryReplay } from '../../slotMachine/replay/historyReplay';
import { normalizeCoins, showCurrency } from '../../utils';
import { countCoins } from '../../utils/helper';

import styles from './history.module.scss';
import LeftSvg from './leftSvg';
import { ReplaySvg } from './replaySvg';
import RightSvg from './rightSvg';
import SkipLeftSvg from './skipLeftSvg';
import SkipRightSvg from './skipRightSvg';

const formatHistory = (historyData: ISlotHistoryData['edges']) => {
  const history = historyData.map((elem) => {
    const node = elem.node;
    const date = node.createdAt;
    const localDate = new Date(date).toLocaleString('en-US', { hour12: true });
    const baseCoinAmount = node.coinAmount;
    const coinAmount = node.userBonus?.bonus ? node.userBonus.bonus.coinAmount * baseCoinAmount : baseCoinAmount;
    const coins = countCoins({
      coinValue: node.coinValue,
      coinAmount: coinAmount,
      lines: baseCoinAmount !== coinAmount ? 1 : setSlotConfig().lineSet.coinAmountMultiplier,
    });
    const currency = setCurrency();
    const bet = formatNumber({ currency, value: coins, showCurrency: showCurrency(setCurrency()) });
    const win = formatNumber({
      currency,
      value: normalizeCoins(node.result.winCoinAmount, node.coinValue),
      showCurrency: showCurrency(setCurrency()),
    });

    return {
      date: localDate,
      win,
      bet,
      gameId: node.id,
      node,
    };
  });

  return history.reverse();
};

const HistoryComponent: React.FC = () => {
  const { head, showBy } = config.historyTable;
  const { t } = useTranslation();
  const [pageAmount, setPageAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const cursorLastRef = useRef('');
  const cursorFirstRef = useRef('');

  const { data: historyData, fetchMore } = useQuery<
    { bets: ISlotHistoryData },
    {
      input: {
        first?: number;
        last?: number;
        before?: string;
        after?: string;
        filter?: { slotId?: string };
      };
    }
  >(slotHistoryGql, {
    variables: {
      input: { last: showBy, filter: { slotId: setSlotConfig().id } },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (historyData?.bets?.edges.length) {
      setPageAmount(Math.ceil(_.get(historyData, 'bets.pageInfo.count', 0) / showBy));
      cursorLastRef.current = historyData.bets.edges[historyData.bets.edges.length - 1]!.cursor;
      cursorFirstRef.current = historyData.bets.edges[0]!.cursor;
    }
  }, [historyData, showBy]);

  if (!historyData?.bets) {
    return (
      <div className={styles.history}>
        <div className={styles.spinner} />
      </div>
    );
  }

  const { pageInfo, edges } = historyData.bets;
  return (
    <div className={styles.history}>
      <div className={styles.container}>
        {formatHistory(edges).map((item) => (
          <div key={item.gameId} className={styles['history-item']}>
            {Object.keys(head).map((key, _index) => (
              <div key={key} className={`${styles['history-item__col']} ${styles[key as keyof typeof head]}`}>
                {t(key as keyof typeof head)}
                {key === 'bet' && item.node.userBonus && item.node.userBonus.bonusId === freeRoundBonusId && (
                  <div className={styles['freeBet']}>{t('freeRound')}</div>
                )}
                <div className={styles.value}>{item[key as keyof typeof head]}</div>
              </div>
            ))}
            <div className={`${styles['history-item__col']} ${styles['replay-button']}`}>
              {t('replay')}
              <Button
                className={`${styles['history-item__col']} ${styles['replay-button']}`}
                onClick={() => {
                  onClickHistoryReplay(item.node);
                }}
              >
                <ReplaySvg />
              </Button>
            </div>
          </div>
        ))}
      </div>
      {pageInfo.count > showBy && (
        <div className={styles.pagination}>
          <Button
            type="button"
            disabled={currentPage === 1}
            className={styles.pagination__btn}
            key="first"
            onClick={() => {
              fetchMore({
                variables: {
                  input: {
                    last: showBy,
                    filter: { slotId: setSlotConfig().id },
                  },
                },
              }).then(() => {
                setCurrentPage(1);
              });
            }}
          >
            <SkipLeftSvg />
          </Button>
          <Button
            disabled={currentPage === 1}
            className={`${styles.pagination__btn}`}
            onClick={() => {
              fetchMore({
                variables: {
                  input: {
                    first: showBy,
                    after: cursorLastRef.current,
                    filter: { slotId: setSlotConfig().id },
                  },
                },
              }).then(() => {
                setCurrentPage(Math.max(1, currentPage - 1));
              });
            }}
          >
            <LeftSvg />
          </Button>
          <Button
            disabled={currentPage === pageAmount}
            className={`${styles.pagination__btn}`}
            onClick={() => {
              fetchMore({
                variables: {
                  input: {
                    last: showBy,
                    before: cursorFirstRef.current,
                    filter: { slotId: setSlotConfig().id },
                  },
                },
              }).then(() => {
                setCurrentPage(Math.min(pageAmount, currentPage + 1));
              });
            }}
          >
            <RightSvg />
          </Button>
          <Button
            disabled={currentPage === pageAmount}
            className={styles.pagination__btn}
            onClick={() => {
              fetchMore({
                variables: {
                  input: {
                    first: ((pageInfo.count - 1) % showBy) + 1,
                    filter: { slotId: setSlotConfig().id },
                  },
                },
              }).then(() => {
                setCurrentPage(pageAmount);
              });
            }}
          >
            <SkipRightSvg />
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(HistoryComponent);
