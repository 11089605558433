import * as PIXI from 'pixi.js';

import { MessageWinBannerProps } from '../../global';
import { ResourceTypes } from '../../resources.d';
import AutoResizeText from '../text/autoResizeText';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  BANNER_POSITION_X,
  MESSAGE_TRUE_END_WIN_BANNER_TITLE_Y,
  MESSAGE_TRUE_END_WIN_BANNER_WIN_AMOUNT_Y,
  MESSAGE_WIN_BANNER_TITLE_Y,
  MESSAGE_WIN_BANNER_WIN_AMOUNT_Y,
  MESSAGE_WIN_BANNER_Y,
} from './config';
import { totalWinAmountTextStyles, totalWinTitleStyles } from './textStyles';

export class MessageWinBanner extends BaseMessageBanner {
  protected override props: MessageWinBannerProps;

  protected banner?: PIXI.Sprite;

  private title?: AutoResizeText;

  private totalWin?: AutoResizeText;

  private isTrueEnd = false;

  constructor(props: MessageWinBannerProps) {
    super(props);
    this.props = props;
    this.x = BANNER_POSITION_X;
    this.y = MESSAGE_WIN_BANNER_Y;
  }

  public override init(trueEnd = false): MessageWinBanner {
    super.init();
    this.isTrueEnd = trueEnd;
    this.banner = this.initBanner();
    this.title = this.initTitle(this.props.title);
    this.totalWin = this.initWinTotal(this.props.totalWin);
    this.addChild(this.banner, this.title, this.totalWin);
    return this;
  }

  protected initBanner(): PIXI.Sprite {
    const banner = new PIXI.Sprite(
      PIXI.Texture.from(this.isTrueEnd ? ResourceTypes.messagebannerSuccesswin : ResourceTypes.messagebannerWin),
    );
    banner.anchor.set(0.5, 0);
    return banner;
  }

  protected initTitle(titleText: string): AutoResizeText {
    const winTitle = new AutoResizeText(titleText, {
      ...totalWinTitleStyles,
      maxWidth: this.banner!.width - 200 ?? 0,
      lineHeight: 200,
      align: 'center',
    });
    winTitle.anchor.set(0.5, 0.5);
    winTitle.y = this.isTrueEnd ? MESSAGE_TRUE_END_WIN_BANNER_TITLE_Y : MESSAGE_WIN_BANNER_TITLE_Y;

    return winTitle;
  }

  protected initWinTotal(totalWin: string): AutoResizeText {
    const winTotal = new AutoResizeText(totalWin, {
      ...totalWinAmountTextStyles,
      maxWidth: this.banner!.width - 200 ?? 0,
    });
    winTotal.anchor.set(0.5, 0);
    winTotal.y = this.isTrueEnd ? MESSAGE_TRUE_END_WIN_BANNER_WIN_AMOUNT_Y : MESSAGE_WIN_BANNER_WIN_AMOUNT_Y;
    return winTotal;
  }
}
