import { useQuery, useReactiveVar } from '@apollo/client';
import { Stage } from '@pixi/layers';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';

import { Game } from '../../game';
import { EventTypes } from '../../global.d';
import { setIsSlotBusy, setIsSpinInProgress, setProgress, setReplayBet, setSlotConfig } from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import Animator from '../../slotMachine/animations/animator';
import { BgmControl } from '../../slotMachine/bgmControl/bgmControl';
import { InitLayer } from '../../slotMachine/components/layer/layer';
import { eventManager } from '../../slotMachine/config';
import { IntroScreen } from '../../slotMachine/introScreen';
import { isDevelopment, isTesting, nextTick, wrap } from '../../utils';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsPopup';
import BetSettingsPopup from '../BetSettings/BetSettingsPopup';
import BuyFeature from '../BuyFeature';
import EventListener from '../EventListener';
import HistoryPopup from '../History/HistoryPopup';
import InfoPopup from '../Info/InfoPopup';
import ReplaySpin from '../ReplaySpin';
import Spin from '../SpinButton';

import styles from './gameScreen.module.scss';
import { preTextureTransfer } from './preTextureTransfer';

const initDevTools = (app: PIXI.Application) => {
  import('../../slotMachine/devTool/devTools').then((module) => {
    new module.DevTools(app);
  });
};

const playBgmForIntroSkip = () => {
  if (AudioApi.restrictionChangedOnIntroScreen) return;
  AudioApi.restrictionChangedOnIntroScreen = true;

  nextTick(() => {
    AudioApi.unSuspend();
    AudioApi.processRestriction({
      restricted: false,
      listToPlay: [],
      onChangeRestriction: () => {
        BgmControl.handleChangeRestriction();
      },
    });
  });
};

const GameScreen: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isSoundOn } = data!;
  const [, setShowContent] = useState(false);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);
  const progress = useReactiveVar(setProgress);

  useEffect(() => {
    AudioApi.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    Game.init();
    const application = Game.getInstance().app;
    application.stage = new Stage();
    Animator.init(application);
    preTextureTransfer(application);
    InitLayer(application);
    application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
    });

    pixiContainerRef.current?.appendChild(application.view);
    application.stage.sortableChildren = true;

    if (isDevelopment() || isTesting()) {
      initDevTools(application);
    }

    const resize = (): void => {
      const parent = pixiContainerRef.current!;
      const width = parent.clientWidth;
      const height = parent.clientHeight;
      application.renderer.resize(width, height);
      eventManager.emit(EventTypes.RESIZE, width, height);
    };

    const resizeHandler = (): void => {
      const userAgent = navigator.userAgent;
      // resize fix for Chrome browsers on Ios devices
      if (userAgent.includes('CriOS') && (userAgent.includes('iPhone') || userAgent.includes('iPad'))) {
        setTimeout(() => {
          resize();
        }, 50);
      } else {
        resize();
      }
    };

    const initSlotMachine = () => {
      SlotMachine.init(application, setSlotConfig(), wrap(setIsSpinInProgress, false), wrap(setIsSlotBusy, false));
      resize();
    };

    if (!setReplayBet()) {
      IntroScreen.init(application);
      eventManager.addListener(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
        initSlotMachine();
      });
    } else {
      // replay start
      initSlotMachine();
      playBgmForIntroSkip();
    }

    resize();

    window.addEventListener(EventTypes.RESIZE, resizeHandler);

    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        eventManager.emit(EventTypes.APP_VISIBILITY_CHANGE, false);
      } else {
        eventManager.emit(EventTypes.APP_VISIBILITY_CHANGE, true);
      }
    });

    return () => window.removeEventListener(EventTypes.RESIZE, resizeHandler);
  }, []);

  useEffect(() => {
    setShowContent(true);
  }, []);

  return (
    <>
      <div ref={pixiContainerRef} className={`${styles.canvas} ${progress.wasLoaded ? '' : styles.intro}`} />
      <EventListener />
      <BuyFeature />
      <AutoPlaySettingsMenu />
      <InfoPopup />
      <HistoryPopup />
      <BetSettingsPopup />
      {setReplayBet() ? <ReplaySpin /> : <Spin />}
    </>
  );
};

export default GameScreen;
