import { Container } from 'pixi.js';

import { EventTypes } from '../../global.d';
import { layerBackGround } from '../components/layer/layer';
import { ViewContainer } from '../components/viewContainer';
import {
  GAME_CONTAINER_HEIGHT,
  SAFE_AREA_PORTRAIT_HEIGHT,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
  SAFE_AREA_PORTRAIT_WIDTH,
  eventManager,
} from '../config';
import { Ambient } from '../gameView/ambient';

export type BackGroundSkin = 'base' | 'freeSpin';

class Background extends ViewContainer {
  private ambient: Container = new Ambient();

  constructor() {
    super();

    this.addChild(this.ambient);
    this.parentLayer = layerBackGround;
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  private calculateSize(width: number, height: number) {
    let newWidth = 0;
    let newHeight = 0;

    const safeAreaRatio = SAFE_AREA_PORTRAIT_WIDTH / SAFE_AREA_PORTRAIT_HEIGHT;

    const isRectangleRatio = +(width / height).toFixed(2) >= +safeAreaRatio.toFixed(2);
    if (isRectangleRatio) {
      newWidth = height * safeAreaRatio;
      newHeight = height;
    } else {
      newWidth = width;
      newHeight = width / safeAreaRatio;
    }

    const scale = newWidth / SAFE_AREA_PORTRAIT_WIDTH;

    const newPositionY = (GAME_CONTAINER_HEIGHT / 2 - SAFE_AREA_PORTRAIT_PIVOT_Y) * scale;

    return {
      newHeight,
      newPositionY,
    };
  }
  override resize(width: number, height: number): void {
    const { newHeight, newPositionY } = this.calculateSize(width, height);
    const calculateY = Math.max((height - newHeight) / 2, 0) + newPositionY;
    this.x = width / 2;
    if (width < height) {
      this.y = calculateY;
    } else {
      this.y = height / 2;
    }
  }
}
export default Background;
